import { Component } from 'react';

interface Props {
  target: string;
}

// Remove target element when this component mounts.
export default class RemoveElement extends Component<Props> {
  componentDidMount() {
    const target = document.querySelector<HTMLDivElement>(this.props.target);

    if (!target) {
      console.warn(`Failed to find target "${this.props.target}"`);
      return;
    }

    target.style.transition = 'opacity 0.4s ease-out';
    target.style.opacity = '0';

    setTimeout(() => {
      if (target && target.parentNode) {
        target.parentNode.removeChild(target);
      }
    }, 500);
  }

  render() {
    return null;
  }
}
