import React, { useState } from 'react';
import { Dialog, Button, Text, IconButton, Heading, Drawer, Card } from '@blockle/ui';
import { hot } from 'react-hot-loader/root';

import './main.css';
import ServiceWorker from 'components/ServiceWorker';
import RemoveElement from 'components/RemoveElement';
import TopBar from 'components/TopBar';
import Box from 'components/Box';

const Main = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  return (
    <div className="Main">
      <TopBar>
        <IconButton label="Open menu" name="menu" onClick={() => setMenuOpen(true)} />
        <IconButton
          label="Open notifications menu"
          name="notification"
          onClick={() => setNotificationsOpen(true)}
        />
      </TopBar>

      <Box scroll grow align="center" column>
        <Heading level="2">Appointments</Heading>

        <div style={{ width: '100%', padding: '0 8px' }}>
          <Card>Card 1</Card>
        </div>
      </Box>

      <Drawer open={menuOpen} onRequestClose={() => setMenuOpen(false)}>
        <TopBar>
          <IconButton label="Close menu" name="close" onClick={() => setMenuOpen(false)} />
          <Heading level="2">Menu</Heading>
          <div>&nbsp;</div> {/* Layout hack */}
        </TopBar>
      </Drawer>

      <Drawer
        open={notificationsOpen}
        onRequestClose={() => setNotificationsOpen(false)}
        position="right"
      >
        <TopBar>
          <IconButton
            label="Close notifications"
            name="close"
            onClick={() => setNotificationsOpen(false)}
          />
          <Heading level="2">Notifications</Heading>
          <div>&nbsp;</div> {/* Layout hack */}
        </TopBar>
      </Drawer>

      <ServiceWorker
        ready={() => (
          <Dialog
            open
            onRequestClose={() => {}}
            actions={
              <>
                <Button inline flat>
                  Update later
                </Button>
                <Button inline flat secondary onClick={() => location.reload()}>
                  Update now
                </Button>
              </>
            }
          >
            <Text>Update ready!</Text>
          </Dialog>
        )}
      />
      <RemoveElement target="#blockle-initial-overlay" />
    </div>
  );
};

export default hot(Main);
