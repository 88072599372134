import React from 'react';

import './top-bar.css';
import { classNames } from 'classNames';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const TopBar = ({ children, className }: Props) => (
  <div className={classNames('TopBar', className)}>{children}</div>
);

export default TopBar;
