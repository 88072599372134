import { Actions, REMOVE_USER } from 'actions/user';
import { Reducer } from 'redux';

export interface Company {
  readonly address: string;
  readonly city: string;
  readonly id: string;
  readonly name: string;
  readonly tel: string;
}

export interface CompanyMap {
  [key: string]: Company;
}

export const initialState: CompanyMap = {};

export const company: Reducer<CompanyMap, Actions> = (state = initialState, action): CompanyMap => {
  switch (action.type) {
    default:
      return state;
  }
};
