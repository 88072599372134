export interface Install {
  onUpdateFound: () => void;
  onUpdateReady: () => void;
  updateInterval: number;
}

export const install = ({ onUpdateFound, onUpdateReady, updateInterval = 0 }: Install) => {
  if ('serviceWorker' in navigator) {
    const { controller } = navigator.serviceWorker;

    navigator.serviceWorker
      .register('./sw.js')
      .then(registration => {
        if (updateInterval > 0) {
          setInterval(() => registration.update(), updateInterval);
        }

        // Ignore first install
        if (!controller) {
          return;
        }

        registration.addEventListener('updatefound', () => {
          const nextWorker = registration.installing;

          if (!nextWorker) {
            console.log('SW update failed');
            return;
          }

          onUpdateFound();

          nextWorker.addEventListener('statechange', () => {
            if (nextWorker.state === 'activated') {
              onUpdateReady();
            }
          });
        });
      })
      .catch(registrationError => {
        console.log('SW registration failed: ', registrationError);
      });
  }
};
