import { applyMiddleware, compose, combineReducers, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer, PersistPartial, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import * as reducers from 'reducers';
import { AppState } from 'reducers'; // tslint:disable-line no-duplicate-imports

const persistConfig: PersistConfig = {
  key: 'app-name',
  storage,
  whitelist: ['user'],
};

const devToolEnhancer =
  process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
    : (x: any) => x;

const enhancer = compose(
  // Use `thunk.withExtraArgument(api)`
  applyMiddleware(thunk),
  devToolEnhancer,
);

const rootReducer = combineReducers({
  ...reducers,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const configureStore = () => {
  const store: Store<AppState> = createStore<AppState & PersistPartial, any, any, void>(
    persistedReducer,
    enhancer,
  );
  const persistor = persistStore(store);

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers');

      store.replaceReducer(nextRootReducer);
    });
  }

  return { store, persistor };
};
