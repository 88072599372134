import { createActionWithPayload } from './helpers';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppState } from 'reducers';

export type User = Partial<AppState['user']>;

export const UPDATE_USER = '@user/UPDATE_USER';
export const updateUser = (user: User) => createActionWithPayload(UPDATE_USER, user);
export type UpdateUser = ReturnType<typeof updateUser>;

export const REMOVE_USER = '@user/REMOVE_USER';
export const removeUser = (id: string) => createActionWithPayload(REMOVE_USER, id);
export type RemoveUser = ReturnType<typeof removeUser>;

export type Actions = UpdateUser | RemoveUser;

// -- Async actions --

export const saveUser = (
  user: User,
): ThunkAction<Promise<UpdateUser>, AppState, void, AnyAction> => dispatch =>
  new Promise(resolve => setTimeout(() => resolve(dispatch(updateUser(user))), 10));
