import { Actions, UPDATE_USER, REMOVE_USER } from 'actions/user';
import { Reducer } from 'redux';

export interface UserState {
  readonly id?: string;
  readonly name?: string;
}

export const initialState: UserState = {};

export const user: Reducer<UserState, Actions> = (state = initialState, action): UserState => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };

    case REMOVE_USER:
      return state;

    default:
      return state;
  }
};
