import React from 'react';
import { classNames } from 'classNames';

import './box.css';

interface Props {
  align?: 'start' | 'center' | 'end';
  children: React.ReactNode;
  className?: string;
  column?: boolean;
  fit?: boolean;
  flex?: boolean;
  grow?: boolean;
  gutter?: boolean;
  justify?: 'start' | 'center' | 'end' | 'space-between' | 'space-around';
  relative?: boolean;
  role?: string;
  scroll?: boolean;
}

const Box = ({
  align,
  children,
  className,
  column,
  fit,
  flex,
  grow,
  gutter,
  justify,
  relative,
  role,
  scroll,
}: Props) => (
  <div
    role={role}
    className={classNames(
      'Box',
      className,
      align && `is-align-${align}`,
      column && 'is-column',
      fit && 'is-fit',
      (flex || align || justify) && 'is-flex',
      grow && 'is-grow',
      gutter && 'is-gutter',
      justify && `is-justify-${justify}`,
      relative && 'is-relative',
      scroll && 'is-scroll',
    )}
  >
    {children}
  </div>
);

export default Box;
