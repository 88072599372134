import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from '@blockle/router';
import { createHashHistory } from 'history';

import { configureStore } from 'config/store';

const { store, persistor } = configureStore();

type Props = {
  children: React.ReactNode;
};

const Shell = ({ children }: Props) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={createHashHistory()}>{children}</Router>
    </PersistGate>
  </Provider>
);

export default Shell;
