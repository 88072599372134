import { Actions } from 'actions/user';
import { Reducer } from 'redux';

export interface AppointmentType {
  readonly id: string;
  readonly name: string;
}

export interface AppointmentState {
  [key: string]: AppointmentType;
}

export const initialState: AppointmentState = {};

export const appointmentType: Reducer<AppointmentState, Actions> = (state = initialState, action): AppointmentState => {
  switch (action.type) {
    default:
      return state;
  }
};
