import { Component } from 'react';

import { install } from './register-sw';

export interface Props {
  loader?(): React.ReactNode;
  ready?(): React.ReactNode;
}

export interface State {
  updateFound: boolean;
  updateReady: boolean;
}

export default class ServiceWorker extends Component<Props, State> {
  state = {
    updateFound: false,
    updateReady: false,
  };

  componentDidMount() {
    // Bail out on local dev
    if (module.hot) {
      return;
    }

    install({
      onUpdateFound: () => this.onUpdateFound(),
      onUpdateReady: () => this.onUpdateReady(),
      updateInterval: 15 * 60 * 1000, // Every 15 min
    });
  }

  onUpdateFound() {
    this.setState({ updateFound: true });
  }

  onUpdateReady() {
    this.setState({ updateReady: true });
  }

  render() {
    const { updateFound, updateReady } = this.state;
    const { loader, ready } = this.props;

    if (updateReady && ready) {
      return ready();
    }

    if (updateFound && loader) {
      return loader();
    }

    return null;
  }
}
