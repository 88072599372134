import React from 'react';
import ReactDOM from 'react-dom';
import { setConfig } from 'react-hot-loader';

import 'theme.css';
import '@blockle/ui/dist/blockle-ui.css';

import { Shell } from 'components/App';
import Main from 'screens/Main';

setConfig({
  ignoreSFC: true,
  pureRender: true,
});

const mountNode = document.getElementById('root');

ReactDOM.render(
  <Shell>
    <Main />
  </Shell>,
  mountNode,
);

// Scroll to active input whenever viewport changes size
// This is most useful on mobile devices, input is not (always) visible after opening the virtual keyboard.
window.addEventListener('resize', () => {
  if (document.activeElement && document.activeElement.nodeName === 'INPUT') {
    document.activeElement.scrollIntoView({ block: 'center' });
  }
});
